// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/services/ApiService.js"
);
import.meta.hot.lastModified = "1722407923816.8562";
}
// REMIX HMR END

class ApiService {
  static async get(_url) {
    const getRes = await fetch(`${_url}`)
    const getResJson = await getRes.json()

    return getResJson
  }

  static async post(_url, _data) {
    const postRes = await fetch(`${_url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(_data)
    })

    return await this.responseHandler(postRes)
  }

  static async patch(_url, _data) {
    const postRes = await fetch(`${_url}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(_data)
    })

    return await this.responseHandler(postRes)
  }

  static async put(_url, _data) {
    const putRes = await fetch(`${_url}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(_data)
    })

    return await this.responseHandler(putRes)
  }

  static async delete(_url, _data) {
    const deleteRes = await fetch(`${_url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(_data)
    })

    return await this.responseHandler(deleteRes)
  }

  static async postFormData(_url, _data) {
    const postRes = await fetch(`${_url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      body: JSON.stringify(_data)
    })

    return await this.responseHandler(postRes)
  }

  static async responseHandler(_res) {
    if (!_res.ok) {
      const { status, statusText } = _res // Assuming the API returns error details in JSON

      switch (status) {
        case 401:
          // Handle unauthorized error (e.g., redirect to login)
          throw new Error(`Unauthorized: ${statusText}`)
        case 403:
          // Handle forbidden error (e.g., show a custom statusText)
          throw new Error(`Access denied: ${statusText}`)
        case 404:
          // Handle not found error
          throw new Error(`Resource not found: ${statusText}`)
        default:
          // Handle other error cases
          throw new Error(`API error: ${status} - ${statusText}`)
      }
    }

    const _resData = await _res.json()
    return _resData // Successful response
  }
}

export default ApiService
